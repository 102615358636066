import { Col, Navbar } from "react-bootstrap"
import Header, { HeaderLanguage, HeaderNav, Menu } from "./Header"
import { Link } from "react-router-dom"

const HeaderLightComponent = () => {
    const darkLogo = '/assets/logo/monte_tek_logo.svg';

    return (
        <Header topSpace={{ md: true }} type="reverse-scroll">
            <HeaderNav theme="light" expand="lg" className="py-[0px] lg:px-[15px] md:px-0" containerClass="sm:px-0">
                <Col className="col-auto col-sm-6 col-lg-2 me-auto ps-lg-0">
                    <Link aria-label="header logo" className="flex items-center" to="/">
                        <Navbar.Brand className="inline-block p-0 m-0 w-[220px] sm:w-[150px] h-auto">
                            {darkLogo && <img className="default-logo" src={darkLogo} data-rjs={darkLogo} alt='monte-tek-logo' />}
                            {darkLogo && <img className="alt-logo" src={darkLogo} data-rjs={darkLogo} alt='monte-tek-logo' />}
                            {darkLogo && <img className="mobile-logo" src={darkLogo} data-rjs={darkLogo} alt='monte-tek-logo' />}
                        </Navbar.Brand>
                    </Link>
                </Col>
                <div className="col-auto hidden order-last md:block">
                    <Navbar.Toggle style={{outline: 'none', border: '0'}} className="md:ml-[10px] sm:ml-0">
                        <span className="navbar-toggler-line"></span>
                        <span className="navbar-toggler-line"></span>
                        <span className="navbar-toggler-line"></span>
                        <span className="navbar-toggler-line"></span>
                    </Navbar.Toggle>
                </div>
                <Navbar.Collapse className="col-auto px-0 justify-end">
                    <Menu style={{ "--base-color": "#0038e3" }} />
                </Navbar.Collapse>
                <Col className="col-auto text-right pe-0">
                    <HeaderLanguage className="xs:pl-[15px]" />
                </Col>
            </HeaderNav>
        </Header>
    )
}

export default HeaderLightComponent;
