const servicesProcessStep = [
    {
        icon: "line-icon-Idea-5",
        title: "services.process_1_header",
        content: "services.process_1_description",
    },
    {
        icon: "line-icon-Fountain-Pen",
        title: "services.process_2_header",
        content: "services.process_2_description",
    },
    {
        icon: "line-icon-Loading-2",
        title: "services.process_3_header",
        content: "services.process_3_description",
    },
    {
        icon: "line-icon-Juice",
        title: "services.process_4_header",
        content: "services.process_4_description",
    },
]

const leanPrinciples = [
    {
        title: "lean.principle_1_header",
        content: "lean.principle_1_description",
    },
    {
        title: "lean.principle_2_header",
        content: "lean.principle_2_description",
    },
    {
        title: "lean.principle_3_header",
        content: "lean.principle_3_description",
    },
    {
        title: "lean.principle_4_header",
        content: "lean.principle_4_description",
    },
    {
        title: "lean.principle_5_header",
        content: "lean.principle_5_description",
    }
]

const whyChooseUs = [
    {
        title: "home.why_1",
    },
    {
        title: "home.why_2",
    },
    {
        title: "home.why_3",
    }
]

const servicesPart1 = [
    {
        icon: "line-icon-Arrow-InsideGap text-fastblue",
        title: "services.1_header",
        content: "services.1_description"
    },
    {
        icon: "line-icon-Settings-Window text-fastblue",
        title: "services.2_header",
        content: "services.2_description"
    }
]

const servicesPart2 = [
    {
        icon: "line-icon-Speach-Bubble7 text-fastblue",
        title: "services.3_header",
        content: "services.3_description"
    },
    {
        icon: "line-icon-Business-ManWoman text-fastblue",
        title: "services.4_header",
        content: "services.4_description"
    }
]

const shortServicesData = [
    {
        icon: "line-icon-Arrow-InsideGap text-gradient bg-gradient-to-r from-[#0038e3] to-[#5c9cff]",
        title: "about.services_1",
    },
    {
        icon: "line-icon-Settings-Window text-gradient bg-gradient-to-r from-[#0038e3] to-[#5c9cff]",
        title: "about.services_2",
    },
    {
        icon: "line-icon-Speach-Bubble7 text-gradient bg-gradient-to-r from-[#0038e3] to-[#5c9cff]",
        title: "about.services_3",
    },
    {
        icon: "line-icon-Business-ManWoman text-gradient bg-gradient-to-r from-[#0038e3] to-[#5c9cff]",
        title: "about.services_4",
    },
]

const processData = [
    {
        title: "about.processData_1_title",
        content: "about.processData_1_description",
    },
    {
        title: "about.processData_2_title",
        content: "about.processData_2_description",
    },
    {
        title: "about.processData_3_title",
        content: "about.processData_3_description",
    }
]

const aboutPageIconData = [
    {
        color: "#0038e3",
        link: "https://www.linkedin.com/in/slavisa-crnogorac-7023733b/",
        icon: "fab fa-linkedin-in"
    },
]

const contactPageSocialIconsData = [
    {
        color: "#007bb6",
        link: "https://www.linkedin.com/in/slavisa-crnogorac-7023733b/",
        icon: "fab fa-linkedin-in"
    },
]

const contactBox = [
    {
        icon: {
            class: "line-icon-Mail-Read text-basecolor"
        },
        title: "contact.email_question",
        description: "contact.email_hover",
        linkTitle: "contact.email_link",
        link: "mailto:info@monte-tek.com"
    },
    {
        icon: {
            class: "line-icon-Phone-2 text-basecolor"
        },
        title: "contact.call_question",
        description: "contact.call_hover",
        linkTitle: "contact.call_link",
        link: "tel:+38762994622"
    },
    {
        icon: {
            class: "line-icon-Approved-Window text-basecolor"
        },
        title: "contact.location_question",
        description: "contact.location_hover",
        linkTitle: "contact.location_link",
        link: "https://maps.app.goo.gl/LLvcQZUTBG6Qeawp8"
    }
]

const leanPageSocialIconsData = [
    {
        color: "#0077b5",
        link: "https://www.linkedin.com/in/slavisa-crnogorac-7023733b/",
        icon: "fab fa-linkedin-in"
    }
]

const comingSoonPageSocialIconsData = [
    {
        color: "#0038e3",
        link: "https://www.linkedin.com/in/slavisa-crnogorac-7023733b/",
        icon: "fab fa-linkedin-in"
    }
]


export {
    servicesProcessStep,
    leanPrinciples,
    whyChooseUs,
    servicesPart1,
    servicesPart2,
    shortServicesData,
    processData,
    aboutPageIconData,
    contactPageSocialIconsData,
    contactBox,
    leanPageSocialIconsData,
    comingSoonPageSocialIconsData
}