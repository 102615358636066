const HeaderData = [
    {
        title: 'header.home',
        link: '/'
    },
    {
        title: 'header.services',
        link: '/services'
    },
    {
        title: 'header.about',
        link: '/about'
    },
    {
        title: 'header.contact',
        link: '/contact'
    }
]

export default HeaderData