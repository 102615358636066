import React, { useRef, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { m } from "framer-motion";
import { Link as ScrollTo } from "react-scroll";
import MouseMove from '../../Components/MouseMove';
import { fadeIn, fadeInLeft, fadeInRight, zoomIn } from '../../Functions/GlobalAnimations';
import { TextAnime } from '../../Components/FancyText/FancyText';
import Buttons from '../../Components/Button/Buttons';
import ProcessStep from '../../Components/ProcessStep/ProcessStep';
import HeaderLightComponent from '../../Components/Header/HeaderLightComponent';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import FooterComponent from '../../Components/Footers/FooterComponent';
import { Parallax } from 'react-scroll-parallax';
import { Link } from 'react-router-dom';
import { whyChooseUs } from '../../Shared/Data';

const HomePage = () => {
    const { t } = useTranslation();
    const splitword = useRef(null);

    const icon = '/assets/logo/monte_tek_icon.svg';
    const home_1 = '/assets/img/home_1.jpg';
    const home_2 = '/assets/img/home_2.jpg';
    const home_5 = '/assets/img/home_5.jpg';
    const home_6 = '/assets/img/home_6.jpg';
    const lean = '/assets/img/lean.jpg';
    const home_header = '/assets/img/home_header.png';

    useEffect(() => {
        let text = splitword.current;
        let splitted_text = text.innerHTML.split("");

        const split_text_html = splitted_text.map((item, i) => {
            if (item === " ") {
                return `<span>&nbsp;</span>`;
            }
            return `<span>${item}</span>`;
        });

        text.innerHTML = split_text_html.join("");

        setTimeout(() => {
            text.querySelectorAll("span").forEach(item => item.classList.add("active"));
        }, 4800);
    }, []);

    return (
        <>
            <Helmet>
                <title>{t("title.home")}</title>
            </Helmet>

            <HeaderLightComponent />

            <div className="bg-white">
                {/* Section Start */}
                <section className="h-[100vh] md:h-[700px] sm:h-[400px] relative bg-center bg-[#f3f3f3] overflow-hidden bg-cover flex justify-center items-end py-[130px]" style={{ backgroundImage: `url(` + home_header + `)` }}>
                    <MouseMove speed={1} className="h-full w-full flex justify-center items-center left-0 top-0 absolute z-[3]">
                        <m.div ref={splitword} className='random-char-fadein-a font-serif text-[200px] relative font-semibold leading-[250px] -tracking-[12px] text-darkgray lg:text-[145px] md:text-[108px] md:leading-[145px] md:-tracking-[4px] sm:text-[108px] sm:leading-[108px] xs:-tracking-[3px] xs:text-[84px] xs:leading-[74px] xxs:text-[52px] xxs:leading-[43px]'>{t("home.slogan_1")}</m.div>
                    </MouseMove>
                    <svg className="drawing-animation absolute top-0 left-0 w-full h-full" width="100%" height="100%" viewBox="0 0 6400 1050" style={{ transform: "scale(1.5)" }}>
                        <defs>
                            <linearGradient id="gradiant" x1="0%" y1="0%" x2="100%" y2="0%">
                                <stop offset="0%" stopColor="#0038e3"></stop>
                                <stop offset="15%" stopColor="#1c58f0"></stop>
                                <stop offset="30%" stopColor="#3b78fa"></stop>
                                <stop offset="50%" stopColor="#5c9cff"></stop>
                                <stop offset="70%" stopColor="#3b78fa"></stop>
                                <stop offset="85%" stopColor="#1c58f0"></stop>
                                <stop offset="100%" stopColor="#0038e3"></stop>
                            </linearGradient>
                        </defs>
                        <g style={{ transform: "translateX(443px)" }}>
                            <path fill="none" stroke="url(#gradiant)" d="
                                M-11,418 
                                L405,418 
                                L405,298 
                                L1180,298 
                                L1180,818 
                                L2057,818 
                                L2057,612 
                                L2717,612 
                                L2717,364 
                                L3693,364 
                                L3693,618 
                                L4245,618 
                                L4245,312 
                                L5220,312 
                                L5220,450 
                                L6400,450" />
                        </g>
                    </svg>
                    <m.span initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 2, duration: 1 }} className="text-xlg font-serif text-darkgray inline-block relative -tracking-[1px] leading-[25px] md:text-[17px] sm:text-lg xs:text-sm xs:absolute xs:bottom-[80px]">{t("home.slogan_2")}</m.span>
                    <m.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 2, duration: 1 }}>
                        <ScrollTo to="down-section" offset={0} delay={0} spy={true} smooth={true} duration={800} className="inline-block absolute left-1/2 bottom-[50px] z-[5] -translate-x-1/2 cursor-pointer">
                            <i className="ti-mouse text-[28px] text-darkgray up-down-ani"></i>
                        </ScrollTo>
                    </m.div>
                </section>
                {/* Section End */}

                {/* Section Start */}
                <section id="down-section" className="overflow-hidden bg-white p-0">
                    <Container fluid>
                        <Row>
                            <Col lg={2} md={4} className="flex flex-col justify-center border-r border-[#f5f5f5] p-0 sm:h-[400px]">
                                <m.div className="w-full content-box-image cover-background overflow-visible relative sm:h-full md:h-[300px] md:w-full" {...fadeIn} style={{ backgroundImage: "url(" + home_5 + ")" }}>
                                    <m.div className="justify-center items-center h-[130px] w-[130px] -right-[70px] -top-[70px] flex rounded-[50%] bg-white absolute box-shadow-extra-large sm:top-0 sm:right-0 md:w-[100px] md:h-[100px] md:-right-[50px] md:-top-[50px] xs:hidden" {...{ ...zoomIn, transition: { duration: 0.7, delay: 0.5 } }}>
                                        <img src={icon} width="70%" alt="monte-tek-icon"></img>
                                    </m.div>
                                </m.div>
                            </Col>
                            <m.div className="flex flex-col justify-center border-b-[8px] border-[#0038e3] xl:px-[5%] px-[8%] py-[12%] lg:py-[8%] lg:px-[7%] md:p-[10%] xs:border-0 col-xl-4 col-lg-6 col-md-8" {...{ ...fadeIn, transition: { delay: 0.4 } }}>
                                <span className="font-serif font-medium text-spanishgray uppercase block mb-[30px]">{t("home.section_1_preheader")}</span>
                                <h2 className="heading-4 font-serif font-semibold text-darkgray -tracking-[2px] mb-12">{t("home.section_1_header")}</h2>
                                <p className="w-[90%] mb-[25px] xl:w-full xs:mb-[15px]">{t("home.section_1_description")}</p>
                                <div className="mt-[25px]">
                                    <Buttons ariaLabel="about-us" to="/about" className="btn-fill btn-fancy font-medium font-serif uppercase rounded-none btn-shadow text-white" size="md" themeColor="#0038e3" color="#fff" title={t("home.section_1_button")} />
                                </div>
                            </m.div>
                            <Col lg={2} md={6} className="flex flex-col justify-center border-[1px] !border-t-0 !border-b-0 border-[#f5f5f5] p-0 sm:h-[400px] sm:border-0 md:h-[600px] md:justify-end">
                                <m.div className="content-box-image cover-background md:h-[300px] sm:h-full" style={{ backgroundImage: "url(" + home_1 + ")" }} {...fadeIn}></m.div>
                            </Col>
                            <Col lg={2} md={6} className="flex flex-col justify-start border-r border-[#f5f5f5] p-0 sm:h-[400px] md:h-[600px]">
                                <m.div className="content-box-image cover-background md:h-[300px] sm:h-full" style={{ backgroundImage: "url(" + home_2 + ")" }} {...{ ...fadeIn, transition: { delay: 0.5 } }}></m.div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                {/* Section End */}

                {/* Section Start */}
                <section className="py-[100px] text-left overflow-hidden md:py-[85px] sm:py-[60px]">
                    <Container>
                        <Row className="items-center">
                            <Col lg={4} className="md:mb-[40px] xs:text-center">
                                <m.h2 className="heading-5 font-semibold -tracking-[1px] text-darkgray font-serif mb-0" {...fadeIn}>{t("home.why_choose")}</m.h2>
                            </Col>
                            <Col lg={8}>
                                <ProcessStep className="xs:block items-center mx-auto justify-center text-center sm:text-start w-100" grid="col-xl-10 gap-y-10" theme="process-step-style-06" data={whyChooseUs} animation={fadeInLeft} />
                            </Col>
                        </Row>
                    </Container>
                </section>
                {/* Section End */}

                {/* Section Start */}
                <section className="py-[80px] overflow-visible bg-[#969896] pb-0 relative md:py-[40px]">
                    <Container>
                        <Row className="items-center justify-center">
                            <div className="relative bottom-[-50px] z-[1] col-lg-6 col-md-10">
                                <Parallax className="lg-no-parallax flex justify-center h-full items-center w-full bg-no-repeat absolute bottom-0 right-[-20px] xs:right-0 lg:!top-[30px]" speed={20}>
                                    <m.div className="marketing-agency-parallx bg-[#0038e3]" {...{ ...fadeInRight, transition: { duration: 0.8 } }}>
                                        <span className="text-overlap-style-04 font-serif font-semibold -tracking-[3px] xs:text-[55px]">{t("home.section_2_word_1")}</span>
                                    </m.div>
                                </Parallax>
                                <Parallax speed={0}>
                                    <m.img
                                        className="lg:w-[80%]"
                                        style={{ width: '444px', height: '642px', objectFit: 'cover' }}
                                        src={home_6}
                                        alt="equipment"
                                        {...{ ...fadeInRight, transition: { ease: [0.25, 1, 0.5, 1], duration: 1 } }}
                                    />
                                </Parallax>
                            </div>
                            <m.div className="mb-[80px] xs:mb-20 md:mt-[100px] col-xl-4 col-lg-5 col-md-10 offset-xl-1" {...fadeIn}>
                                <h2 className="heading-5 font-medium font-serif text-white">{t("home.section_2_header_1")}<span className="font-semibold">{t("home.section_2_header_2")}</span>{t("home.section_2_header_3")}</h2>
                                <p className="text-[#fff] text-xmd leading-[32px] w-[95%] mb-[25px] opacity-60">{t("home.section_2_description")}</p>
                                <Link to="/services" className="mt-[15px] text-[#fff] text-xmd font-serif hover:text-[#fff]">{t("home.section_2_button")}
                                    <i className="line-icon-Arrow-OutRight text-[40px] inline-block ml-[15px] align-middle"></i>
                                </Link>
                            </m.div>
                        </Row>
                    </Container>
                </section>
                {/* Section End */}

                {/* Section Start */}
                <m.section className="overflow-visible block p-0 relative" {...fadeIn}>
                    <div className="right-0 bottom-[-50px] font-semibold tracking-[-10px] leading-[200px] text-[#fff] text-[200px] font-serif absolute lg:text-[160px] lg:leading-[160px] md:hidden">{t("home.section_2_word_2")}</div>
                </m.section>
                {/* Section End */}

                {/* Section Start */}
                <section className="py-[160px] bg-center bg-no-repeat bg-white lg:py-[120px] md:py-[95px] sm:py-[80px] xs:py-[50px] overflow-x-hidden">
                    <Container>
                        <Row className="justify-center items-center flex">
                            <m.div className="col-md-12 text-center" {...{ ...fadeIn, transition: { duration: 0.5, delay: 0.4 } }}>
                                <h2 className="heading-5 font-serif font-light mb-[20px] text-darkgray -tracking-[1px] md:mb-0">{t("home.anime_header")}</h2>
                                <TextAnime duration={2000} color="#0038e3" className="text-[#0038e3] font-serif -tracking-[5px] text-center leading-[170px] text-[110px] font-semibold lg:text-[80px] lg:leading-[140px] md:text-[60px] md:leading-[110px] sm:text-[38px] sm:leading-[65px] sm:-tracking-[3px]" animation="slide" data={[t("home.anime_1"), t("home.anime_2"), t("home.anime_3"), t("home.anime_4")]} />
                            </m.div>
                        </Row>
                    </Container>
                </section>
                {/* Section End */}

                {/* Section Start */}
                <section
                    className="bg-cover relative bg-center bg-no-repeat realtive overflow-hidden py-[160px] lg:py-[120px] md:py-[95px] sm:py-[80px] xs:py-[50px]"
                    style={{
                        backgroundImage:
                            "url(" + lean + ")",
                    }}
                >
                    <div className="opacity-50 bg-darkgray absolute top-0 left-0 w-full h-full"></div>
                    <Container>
                        <Row className="items-center justify-center">
                            <Col className="text-center relative">
                                <h2 className="heading-6 font-serif text-xlg md:text-lg xs:text-lg font-semibold text-[#fff] uppercase mb-0">
                                    {t("home.cta_lean_header")} &nbsp;
                                    <Link
                                        aria-label="button"
                                        to="/lean"
                                        className="font-semibold text-xlg pt-0 font-serif uppercase text-decoration-line-bottom md:text-lg md:leading-[26px] text-[#fff] hover:text-white"
                                    >
                                        {t("home.cta_lean_button")}
                                    </Link>
                                </h2>
                            </Col>
                        </Row>
                    </Container>
                </section>
                {/* Section End */}

                {/* Section Start */}
                <section className="py-[80px] overflow-hidden md:py-[40px]">
                    <Container>
                        <Row className="md:justify-center">
                            <Col lg={7} sm={10} className="md:mt-12 md:mb-12 md:text-center">
                                <m.h2 className="heading-4 font-semibold text-darkgray font-serif mb-0" {...{ ...fadeIn, transition: { delay: 0.2 } }}>{t("home.cta_bottom_header")}</m.h2>
                            </Col>
                            <m.div className="col-lg-5 pl-24 lg:pl-[15px] md:mb-12 md:text-center" {...{ ...fadeIn, transition: { delay: 0.4 } }}>
                                <span className="mt-[7px] text-darkgray text-xmd font-serif block">{t("home.cta_bottom_description")}</span>
                                <h3 className="heading-4 text-[#f4d956] font-semibold font-serif mb-0 mt-[5px]">
                                    <a aria-label="mail" href={"mailto:" + t("home.cta_bottom_mail")} className="text-decoration-line-bottom-thick text-[#0038e3] hover:opacity-50 hover:text-[#828282]">{t("home.cta_bottom_mail")}</a>
                                </h3>
                            </m.div>
                        </Row>
                    </Container>
                </section>
                {/* Section End */}
            </div>

            <FooterComponent />
        </>
    )
}

export default HomePage;