import React, { memo } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Footer } from './Footer';
import { useTranslation } from 'react-i18next';

const FooterComponent = () => {
    const { t } = useTranslation();
    const logo = '/assets/logo/monte_tek_logo_text_white.svg';

    return (
        <Footer className="bg-[#262b35] text-slateblue" theme="dark">
            <div className="py-[7%] lg:py-[8%] sm:py-[50px]">
                <Container>
                    <Row className="justify-between md:justify-center">
                        <Col className="m-0 md:text-center sm:text-center" xl={6} lg={7} md={6} sm={12}>
                            <Link to="/" className="text-slateblue mb-[15px] mt-[5px] inline-block">
                                {logo && <img loading="lazy" src={logo} alt="monte_tek_logo_text" width="180" height="auto" />}
                            </Link>
                            <p className="mb-[15px] text-slateblue md:mb-[15px] md:text-center sm:text-center">{t("footer.description")}</p>
                            <Link className="mr-4 text-white cursor-pointer" to={t("footer.company_linkedin")} aria-label="social icon" target="_blank" rel="noreferrer">
                                <i className="fab fa-linkedin brand-icon"></i>
                            </Link>
                            <span>© {t("footer.all_rights_reserved")} {new Date().getFullYear()} <Link to="/" className="font-bold">{t("footer.company_name")}</Link></span>
                        </Col>
                        <Col xl={1} className="xs:mb-[25px] block lg:hidden">
                            <span className="mb-[20px] font-serif block font-medium text-themecolor xs:mb-[10px]">{t("footer.see_more")}</span>
                            <ul>
                                <li className="mb-[7px] last:mb-0"><Link aria-label="footer menu link" to="/">{t("footer.home")}</Link></li>
                                <li className="mb-[7px] last:mb-0"><Link aria-label="footer menu link" to="/services">{t("footer.services")}</Link></li>
                                <li className="mb-[7px] last:mb-0"><Link aria-label="footer menu link" to="/about">{t("footer.about")}</Link></li>
                                <li className="mb-[7px] last:mb-0"><Link aria-label="footer menu link" to="/contact">{t("footer.contact")}</Link></li>
                            </ul>
                        </Col>
                        <Col lg={4} md={6} sm={12} className="lg:mt-0 md:mt-5 sm:mt-0 sm:mt-20 xs:mb-[25px] md:text-center sm:text-center">
                            <span className="mb-[20px] font-serif block font-medium text-themecolor sm:mb-[10px]">{t("footer.contact_us")}</span>
                            <p className="mb-[20px]">{t("footer.company_location")}</p>
                            <div><i className="feather-phone-call text-sm mr-[10px] text-themecolor"></i><a aria-label="mail" href={'tel:' + t("footer.company_phone_number_alt")}>{t("footer.company_phone_number")}</a></div>
                            <div><i className="feather-mail text-sm mr-[10px] text-themecolor"></i><a aria-label="tel" href={'mailto:' + t("footer.company_email")}>{t("footer.company_email")}</a></div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Footer>
    )
}

export default memo(FooterComponent)