const ProcessStepData01 = [
    {
        icon: "line-icon-Idea-5",
        title: "services.process_1_header",
        content: "services.process_1_description",
    },
    {
        icon: "line-icon-Fountain-Pen",
        title: "services.process_2_header",
        content: "services.process_2_description",
    },
    {
        icon: "line-icon-Loading-2",
        title: "services.process_3_header",
        content: "services.process_3_description",
    },
    {
        icon: "line-icon-Juice",
        title: "services.process_4_header",
        content: "services.process_4_description",
    },
]

const ProcessStepData02 = [
    {
        title: "Project research",
        content: "Lorem ipsum amet consectetur do eiusmod tempor incididunt",
    },
    {
        title: "Sketches and design",
        content: "Lorem ipsum amet consectetur do eiusmod tempor incididunt",
    },
    {
        title: "Seleted concept",
        content: "Lorem ipsum amet consectetur do eiusmod tempor incididunt",
    }
]

const ProcessStepData03 = [
    {
        title: "RESEARCH",
        content: "Lorem ipsum dolor consectetur adipiscing eiusmod tempor",
    },
    {
        title: "SKETCHES",
        content: "Lorem ipsum dolor consectetur adipiscing eiusmod tempor",
    },
    {
        title: "CONCEPT",
        content: "Lorem ipsum dolor consectetur adipiscing eiusmod tempor",
    },
    {
        title: "PRESENTATION",
        content: "Lorem ipsum dolor consectetur adipiscing eiusmod tempor",
    },
]

const ProcessStepData04 = [
    {
        title: "lean.principle_1_header",
        content: "lean.principle_1_description",
    },
    {
        title: "lean.principle_2_header",
        content: "lean.principle_2_description",
    },
    {
        title: "lean.principle_3_header",
        content: "lean.principle_3_description",
    },
    {
        title: "lean.principle_4_header",
        content: "lean.principle_4_description",
    },
    {
        title: "lean.principle_5_header",
        content: "lean.principle_5_description",
    }
]

const ProcessStepData05 = [
    {
        title: "Start market research",
        content: "Lorem ipsum is simply text of the printing and typesetting industry lorem ipsum",
    },
    {
        title: "Discussion of the idea",
        content: "Lorem ipsum is simply text of the printing and typesetting industry lorem ipsum",
    },
    {
        title: "Production planning",
        content: "Lorem ipsum is simply text of the printing and typesetting industry lorem ipsum",
    }
]

const ProcessStepData06 = [
    {
        title: "home.why_1",
    },
    {
        title: "home.why_2",
    },
    {
        title: "home.why_3",
    }
]

export { ProcessStepData01, ProcessStepData02, ProcessStepData03, ProcessStepData04, ProcessStepData05, ProcessStepData06 }